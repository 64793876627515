import { styled } from '@styledComponents';
import { Box } from '@components/Box';
import { Text } from '@components/typography';
import { themeColor, themeMediaQuery } from '@utils/styledTheme.util';
import { JoykitColor } from '@components/themes';

export const Eyebrow = styled(Text)`
  top: 16px;
  position: relative;
  ${themeMediaQuery('below_sm')} {
    top: 0px;
  }
`;

export interface FlagProps {
  flagColor: JoykitColor;
  flagBackgroundColor: JoykitColor;
  belowSm?: { flagColor: JoykitColor; flagBackgroundColor: JoykitColor };
}

export const StyledBox = styled(Box)<FlagProps>`
  width: 109px;
  g {
    fill: ${({ belowSm, flagColor }) => themeColor(belowSm ? belowSm.flagColor : flagColor)};
  }
  rect {
    fill: ${({ belowSm, flagBackgroundColor }) => themeColor(belowSm ? belowSm.flagBackgroundColor : flagBackgroundColor)};
  }
  ${themeMediaQuery('sm')} {
    g {
      fill: ${props => themeColor(props.flagColor)};
    }
    rect {
      fill: ${props => themeColor(props.flagBackgroundColor)};
    }
  }
`;
