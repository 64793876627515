import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { DexecureOptimizationMode, dexecureSettings } from '@utils/cloudAssetUrl.util';
import bodymovin, { AnimationConfigWithPath, AnimationItem } from 'lottie-web';

// Below is a snippet of a typical lottie JSON file.
// the assets array of this JSON is what we are interested in.
// when loaded as a JSON file, lottie treats the URL of an asset as relative to the JSON file. It does something like:
//   folder = parent folder of JSON file.
//   URL of asset = folder + asset.u + asset.p
// When loaded as JSON data, it uses the assetPath property from the animation config object. It does something like:
//   URL of asset = config.assetPath + asset.p
// The asset.u property is ignored.
//
// {
//   "v": "5.6.10",
//   "fr": 29.9700012207031,
//   "ip": 20.0000008146167,
//   "op": 276.00001124171,
//   "w": 1200,
//   "h": 1200,
//   "nm": "RSVP_MultipleEditors_v1",
//   "ddd": 0,
//   "assets": [
//     {
//       "id": "image_0",
//       "w": 512,
//       "h": 512,
//       "u": "images/",
//       "p": "img_0.jpg",
//       "e": 0
//     },
//     {
//       "id": "image_1",
//       "w": 512,
//       "h": 512,
//       "u": "images/",
//       "p": "img_1.jpg",
//       "e": 0
//     },
//     { ...

type LottieJsonResponse = Readonly<{
  assets?: ReadonlyArray<{
    p?: string;
    u?: string;
  }>;
}>;

const dexecureQueryString = (optimization: DexecureOptimizationMode) => {
  const queryStrings = [];
  if (dexecureSettings.cacheBuster) {
    queryStrings.push(`ver=${dexecureSettings.cacheBuster}`);
  }

  if (dexecureSettings.defaultOptimation) {
    queryStrings.push(`opt=${optimization ?? dexecureSettings.defaultOptimation}`);
  }

  if (queryStrings.length) {
    return '?' + queryStrings.join('&');
  } else {
    return '';
  }
};

interface BaseLottieConfig
  extends Readonly<{
    containerRef: RefObject<HTMLDivElement>;
    speed?: number;
  }> {}
interface LottieConfig extends BaseLottieConfig {
  readonly isLoadInitially: true;
}
interface LazyLottieConfig extends BaseLottieConfig {
  readonly isLoadInitially?: false;
}
interface AnimationHookReturn {
  animationRef: RefObject<AnimationItem | null>;
}
interface AnimationLazyHookReturn {
  animationRef: RefObject<AnimationItem | null>;
  loadAnimation: () => void;
}

interface AnimationConfigWithForcePath extends Omit<AnimationConfigWithPath, 'container'> {
  path: string;
}

export function useLottieAnimation(
  config: AnimationConfigWithForcePath,
  customConfig: LottieConfig,
  optimization?: DexecureOptimizationMode
): AnimationHookReturn;
export function useLottieAnimation(
  config: AnimationConfigWithForcePath,
  customConfig: LazyLottieConfig,
  optimization?: DexecureOptimizationMode
): AnimationLazyHookReturn;
export function useLottieAnimation(
  config: AnimationConfigWithForcePath,
  customConfig: LottieConfig | LazyLottieConfig,
  optimization?: DexecureOptimizationMode
): AnimationHookReturn | AnimationLazyHookReturn {
  const animationRef = useRef<AnimationItem | null>(null);
  const requestRef = useRef<XMLHttpRequest>();
  // we don't handle changes to config anyways, but this way we optimize to not rerender gsap animations
  const [initialConfig] = useState(config);
  const { containerRef, speed, isLoadInitially } = customConfig;
  const loadHandler = useCallback(() => {
    const lottieJson = JSON.parse(requestRef.current!.responseText) as LottieJsonResponse;
    lottieJson.assets?.forEach(asset => {
      asset.p = asset.p + dexecureQueryString(optimization);
      asset.u = undefined;
    });
    // we assume that containerRef.current is set by the time this code run to prevent rerendering(as it may cause rerendering of gsap animations which is not cheap operation)
    if (!containerRef.current) {
      throw new Error(
        'containerRef.current should be set by the time this code runs. For cases of conditional rendering run lazy load after containerRef.current gets set'
      );
    }
    if (lottieJson) {
      const jsonFilePath = initialConfig.path;
      const lastSlash = jsonFilePath.lastIndexOf('/');
      const assetsPath = jsonFilePath.substr(0, lastSlash) + '/images/';
      animationRef.current = bodymovin.loadAnimation({
        ...initialConfig,
        animationData: lottieJson,
        assetsPath,
        container: containerRef.current,
        path: undefined,
      });

      if (speed !== undefined) {
        bodymovin.setSpeed(speed, initialConfig.name);
      }
    }
  }, [containerRef, optimization, initialConfig, speed]);
  const loadAnimation = useCallback(() => {
    if (requestRef.current || typeof XMLHttpRequest === 'undefined') {
      return;
    }
    const jsonFilePath = initialConfig.path;

    const request = (requestRef.current = new XMLHttpRequest());
    request.addEventListener('load', loadHandler);
    request.open('GET', jsonFilePath);
    request.send();
  }, [initialConfig, requestRef, loadHandler]);

  useEffect(() => {
    if (isLoadInitially) {
      loadAnimation();
    } else {
      const document = window?.document;
      if (document && document.head) {
        const prefetchLinkElement = document.createElement('link');
        prefetchLinkElement.as = 'fetch';
        prefetchLinkElement.crossOrigin = 'anonymous';
        prefetchLinkElement.href = initialConfig.path;
        prefetchLinkElement.rel = 'prefetch';
        document.head.appendChild(prefetchLinkElement);
      }
    }
    return () => {
      requestRef.current?.removeEventListener('load', loadHandler);
    };
  }, [isLoadInitially, loadAnimation, requestRef, loadHandler, initialConfig.path]);

  return isLoadInitially
    ? {
        animationRef,
      }
    : {
        animationRef,
        loadAnimation,
      };
}
