export const VIDEO_ASSETS = {
  mobile: {
    url: 'https://player.vimeo.com/video/908845056?badge=0&autopause=0&player_id=0&app_id=58479',
    poster: 'home/baby-announcement/mobile_v3.jpg',
  },
  desktop: {
    url: 'https://player.vimeo.com/video/908844977?badge=0&autopause=0&player_id=0&app_id=58479',
    poster: 'home/baby-announcement/desktop_v2.jpg',
  },
};

export const HEIGHT = '39.4vw';
export const HEIGHT_MOBILE = '84vh';
export const HEIGHT_MOBILE_MIN = '750px';
export const HEIGHT_TABLET = '125vh';

export const CTA_HEIGHT = '48px';
export const CTA_HEIGHT_MOBILE = '40px';

export const WIDTH_TITLE = '346px';
export const WIDTH_BODY = '395px';
export const WIDTH_BODY_MOBILE = '266px';
export const WIDTH_BODY_XL = '480px';
export const CONTAINER_PADDING = '88px';
