import React, { useEffect, useState } from 'react';
import { NewFlag } from '@components/NewFlag';
import { homePageData } from '@data-ts/pages/home';
import { VimeoVideo } from '@components/VimeoVideo';
import { VIDEO_ASSETS } from './BabyRegistry.constants';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { useThemeMediaQuery } from '@hooks/useThemeMediaQuery';
import { SectionWrapper, LottieContainer, StyledTextPairing, TextContainer, ExploreButton } from './BabyRegistry.styles';

const { title, body, linkText, link } = homePageData.babyRegistry;

interface BabyRegistryProps {
  page: MarcomPageName;
}

export const BabyRegistry: React.FC<BabyRegistryProps> = ({ page }) => {
  const isMobile = useThemeMediaQuery('below_sm');
  const [videoAssets, setVideoAssets] = useState(VIDEO_ASSETS.mobile);

  useEffect(() => setVideoAssets(isMobile ? VIDEO_ASSETS.mobile : VIDEO_ASSETS.desktop), [isMobile]);

  return (
    <SectionWrapper paddingBottom={[8, 0]} alignItems={['flex-end', 'center']}>
      <TextContainer paddingY={[8, 0]}>
        <LottieContainer flexDirection="column" justifyContent={['flex-start', 'center']} alignItems={['center', 'flex-start']}>
          <NewFlag
            flagColor="white"
            flagBackgroundColor="accentBlue4"
            belowSm={{ flagColor: 'white', flagBackgroundColor: 'accentBlue4' }}
            speed={1.5}
          />
          <StyledTextPairing
            titleVariant="calloutHed40Bold"
            titleColor="neutra6"
            titleTagName="h3"
            title={title}
            body={body}
            bodyVariant="dek24"
            bodyColor="neutra5"
            marginTop={[6, 0]}
            marginBottom={6}
            alignment={['center', 'left']}
            textAlign={['center', 'left']}
          />
          <ExploreButton id={`${page}BabyRegistryBtn`} forwardedAs="a" href={link} variant="secondary">
            {linkText}
          </ExploreButton>
        </LottieContainer>
      </TextContainer>
      <LazyLoadWrapper>
        <VimeoVideo
          aspectRatio={9 / 16}
          title={title}
          embedUrl={videoAssets.url}
          posterUrl={videoAssets.poster}
          autoPlay
          background
          muted
          controls={false}
          loop
          autoPause={false}
          cover
          posterOptimization="default"
          alt="baby-registry"
        />
      </LazyLoadWrapper>
    </SectionWrapper>
  );
};
