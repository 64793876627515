import { useLayoutEffect, useRef } from 'react';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { useLottieAnimation } from '@hooks/useLottieAnimation';

export const useNewFlagController = (jsonUrl: string, speed: number = 1) => {
  const lottieContainerRef = useRef<HTMLDivElement>(null);
  const lottieRef = useRef<HTMLDivElement>(null);
  const { animationRef, loadAnimation } = useLottieAnimation(
    {
      path: cloudAssetUrl(jsonUrl),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      name: 'new flag streaming',
      rendererSettings: {
        progressiveLoad: false,
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
    { containerRef: lottieRef }
  );

  const playBodimovin = () => {
    const lottieContainer = lottieContainerRef.current;
    let position = lottieContainer ? lottieContainer.getBoundingClientRect() : { top: 0, bottom: 0 };
    const animationItem = animationRef.current;
    if (animationItem && position.top >= 0 && position.bottom <= window.innerHeight) {
      animationItem.setSpeed(speed);
      animationItem.goToAndPlay(0);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', playBodimovin, { passive: true });
    return () => {
      window.removeEventListener('scroll', playBodimovin);
    };
  });

  return {
    lottieRef,
    lottieContainerRef,
    initBodyMovinAnimation: loadAnimation,
  };
};
