import { styled } from '@styledComponents';
import { Flex } from '@components/Flex';
import { Button } from '@components/Button';
import { TextPairing } from '@components/TextPairing';
import { themeSpace, themeColor, themeMediaQuery } from '@utils/styledTheme.util';
import {
  HEIGHT,
  HEIGHT_MOBILE,
  HEIGHT_MOBILE_MIN,
  HEIGHT_TABLET,
  CTA_HEIGHT,
  CTA_HEIGHT_MOBILE,
  WIDTH_TITLE,
  WIDTH_BODY,
  WIDTH_BODY_MOBILE,
  WIDTH_BODY_XL,
  CONTAINER_PADDING,
} from './BabyRegistry.constants';

export const SectionWrapper = styled(Flex)`
  position: relative;
  height: ${HEIGHT};
  ${themeMediaQuery('below_sm')} {
    height: ${HEIGHT_MOBILE};
    min-height: ${HEIGHT_MOBILE_MIN};
    justify-content: center;
  }
  ${themeMediaQuery('tablet')} {
    height: ${HEIGHT_TABLET};
  }
`;

export const TextContainer = styled(Flex)`
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: flex-start;
  ${themeMediaQuery('sm')} {
    align-items: center;
  }
`;

export const LottieContainer = styled(Flex)`
  padding: 0px ${themeSpace(6)};
  width: 100%;
  min-height: 100%;
  ${themeMediaQuery('tablet')} {
    padding-top: ${themeSpace(10)};
  }
  ${themeMediaQuery('sm')} {
    width: fit-content;
    margin-left: auto;
    padding-top: 0;
    transform: scale(0.8);
  }
  ${themeMediaQuery('md')} {
    transform: scale(1);
    padding: 0px ${CONTAINER_PADDING};
  }
`;

export const StyledTextPairing = styled(TextPairing)`
  .text-pairing-title {
    max-width: ${WIDTH_TITLE};
    margin-bottom: ${themeSpace(6)};
  }
  .text-pairing-body {
    max-width: ${WIDTH_BODY_MOBILE};
  }
  ${themeMediaQuery('sm')} {
    .text-pairing-title {
      max-width: ${WIDTH_TITLE};
    }
    .text-pairing-body {
      max-width: ${WIDTH_BODY};
    }
  }
  ${themeMediaQuery('xl')} {
    .text-pairing-body {
      max-width: ${WIDTH_BODY_XL};
    }
  }
`;

export const ExploreButton = styled(Button)`
  display: flex;
  align-items: center;
  height: ${CTA_HEIGHT_MOBILE};
  padding: 0 ${themeSpace(5)};
  background: transparent;
  color: ${themeColor('mono1')};
  border: 2px solid ${themeColor('mono1')};
  ${themeMediaQuery('sm')} {
    height: ${CTA_HEIGHT};
    padding: 0 ${themeSpace(6)};
  }
`;
