import React from 'react';
import { Box } from '@components/Box';
import { Flex } from '@components/Flex';
import { JoykitColor } from '@components/themes';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { useNewFlagController } from './NewFlag.controller';
import { Eyebrow, FlagProps, StyledBox } from './NewFlag.styles';

interface NewFlagProps extends FlagProps {
  title?: string;
  titleColor?: JoykitColor;
  jsonUrl?: string;
  speed?: number;
}

export const NewFlag: React.FC<NewFlagProps> = ({
  title,
  titleColor = 'neutra6',
  jsonUrl = 'home/rachel-zoe/data.json',
  speed,
  ...props
}) => {
  const { lottieRef, lottieContainerRef, initBodyMovinAnimation } = useNewFlagController(jsonUrl, speed);
  return (
    <Flex ref={lottieContainerRef} alignItems="center" flexDirection={['column', 'row']}>
      <LazyLoadWrapper beforeLoad={initBodyMovinAnimation}>
        <div></div>
      </LazyLoadWrapper>
      <StyledBox ref={lottieRef} marginLeft={[0, -6]} marginTop={[-6, 0]} {...props} />
      {title && (
        <Eyebrow variant="sectionEyeBrow24" tagName="h4" color={titleColor}>
          {title}
        </Eyebrow>
      )}
    </Flex>
  );
};
