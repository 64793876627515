export const homePageData = {
  seo: {
    title: 'Joy - Wedding Planning, Redesigned',
    content:
      'Experience wedding planning the way it should be, from beautiful wedding websites and invitations, to registries and online RSVP.',
  },

  sections: {
    hero: {
      title: 'The smarter way to plan&nbsp;your big&nbsp;day.',
      step1Content: 'Experience wedding planning the way it should be — intuitive, thoughtful, and personal.',
      step2Content: 'Countless happy couples, one connected planning experience.',
      titleV2: 'Plan your forever, better.',
      step1ContentV2: 'Design a personalized website, create an all-in-one registry, and experience wedding planning the way it should be.',
      getStartedBtn: 'Get Started',
      findEventBtn: 'Find an Event',
      waveAlt: 'hero wave svg',
      uiMagic: {
        colorsAlt: 'Colors',
        sendReminderAlt: 'Send reminder',
        statusesAlt: 'Statuses',
        saveTheDateAlt: 'Save the Date',
        hotelBookingAlt: 'Hotel booking',
        registryTypesAlt: 'Registry types',
        rehearsalDinnerAlt: 'Rehearsal dinner',
        weddingTableGroupAlt: 'Wedding table group',
        songRequestsAlt: 'Song requests',
        rsvpFormAlt: 'Rsvp form',
        guestsFilterAlt: 'Guests filter',
        mapAlt: 'Map',
        guestsCountSummaryAlt: 'Guests count summary',
        rsvpTogetherAlt: 'Rsvp together',
      },
    },
    features: {
      weddingWebsite: {
        foregroundAlt: 'Couple in the city',
        uiAlt: 'Wedding website UI',
        listItemLabel: 'Wedding Website',
      },
      guestList: {
        foregroundAlt: 'Couple on the beach',
        uiAlt: 'Guest List UI',
        listItemLabel: 'Guest List',
      },
      saveTheDates: {
        foregroundAlt: '',
        uiAlt: 'Save the Dates UI',
        listItemLabel: 'Save the Dates',
      },
      invitations: {
        foregroundAlt: 'Couple in the city',
        uiAlt: 'Invitations UI',
        listItemLabel: 'Invitations',
      },
      smartRSVP: {
        foregroundAlt: 'Couple in the city',
        uiAlt: 'smart RSVP UI',
        listItemLabel: 'Smart RSVP',
      },
      registry: {
        foregroundAlt: 'Couple in the city',
        uiAlt: 'Registry UI',
        listItemLabel: 'Registry',
      },
      guestApp: {
        foregroundAlt: 'Couple in the city',
        listItemLabel: 'Mobile App',
      },
      hotelRoomBlocks: {
        uiAlt: 'Hotel Room Blocks',
        foregroundAlt: 'Couple in the city',
        backgrounAlt: 'Hotel Room',
        listItemLabel: 'Hotel Room Blocks',
      },
    },
    couplesTestimonials: {
      title: 'Trusted by hundreds of <br /> thousands of couples <br /> just like you',
      centerImageContent: 'Trusted by hundreds of <br /> thousands of couples <br /> just like you',
      altImage: 'Testimonials image',
    },
  },

  waterfallData: {
    title: 'Plan your forever, better.',
    id: 'getstarted',
    ctaBtn: 'Get Started',
  },

  testimonials: [
    {
      img: 'home/couples/testimonial-couples-17.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-malika-dyron/',
      names: 'Malika & Dyron',
      quote:
        'It was really easy to set up! Joy took the guesswork out of what should I include and how should I make it look. It also took hours of coding out of my hands – woot!',
      photoCredit: '',
      photoUrl: '/',
      instaCredit: '',
      instaUrl: '',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-7.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-daphne-jorge/',
      names: 'Daphne & Jorge',
      quote:
        'I fell in love with the interface. I’m also excited to have my guests download the app so they can post pictures and communicate.',
      photoCredit: 'Photo by RJ of Foxgrove Films',
      photoUrl: 'https://foxgrovefilms.com/',
      instaCredit: '@foxgrovefilms',
      instaUrl: 'https://www.instagram.com/foxgrovefilms/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-1-v2.jpg',
      storyUrl: 'https://withjoy.com/blog/category/proposal-story/',
      names: 'Jose & Dan',
      quote: 'Joy is easy to use. It also has a contemporary feel to it and more choices to choose from than other wedding sites!',
      photoCredit: 'Photo by Katelyn Barthlome Photography',
      photoUrl: 'https://www.katelynbarthlome.com/',
      instaCredit: '@katelynbarthlomephotography',
      instaUrl: 'https://www.instagram.com/katelynbarthlomephotography/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-11.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-lucy-marvin/',
      names: 'Lucy & Marvin',
      quote: 'It is extremely user-friendly! Even my 85-year-old grandma RSVP’d on her own from London.',
      photoCredit: 'Photo by Mark Fierst Photography',
      photoUrl: 'https://foxgrovefilms.com/',
      instaCredit: '@foxgrovefilms',
      instaUrl: 'https://www.instagram.com/foxgrovefilms/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-12.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-jordan-tyler/',
      names: 'Jordan & Tyler',
      quote: 'It takes care of basically everything for the guests!',
      photoCredit: 'Photo by Andrew May Photography',
      photoUrl: 'https://andrewmayphotography.com/',
      instaCredit: '@allegoryandelm',
      instaUrl: 'https://www.instagram.com/allegoryandelm/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-20.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-lucy-marvin/',
      names: 'Lucy & Marvin',
      quote: 'It is extremely user-friendly! Even my 85-year-old grandma RSVP’d on her own from London.',
      photoCredit: 'Mark Fierst Photography',
      photoUrl: 'https://www.markfierstphotography.com/',
      instaCredit: '@markfierstphoto',
      instaUrl: 'https://www.instagram.com/markfierstphoto/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-16.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-nicholas-micah/',
      names: 'Nicholas & Micah',
      quote: 'Joy thinks of everything when it comes to creating and designing a wedding website.',
      photoCredit: 'Shay & Olive Photography',
      photoUrl: 'https://www.shayandolive.com/',
      instaCredit: '@shayandolivephoto',
      instaUrl: 'https://www.instagram.com/shayandolivephoto',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-3.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-katie-thanit/',
      names: 'Katie &amp; Thanit',
      quote: 'Our favorite part is how prominent photos are and how sleek and modern the site is!',
      photoCredit: 'Main + Simple Photography',
      photoUrl: 'https://www.mainandsimplephoto.com/',
      instaCredit: '@mainandsimple',
      instaUrl: 'https://www.instagram.com/mainandsimple/',
      showQuote: true,
    },
    {
      img: 'home/couples/testimonial-couples-19.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-katie-thanit/',
      names: 'Katie &amp; Thanit',
      quote: 'Our favorite part is how prominent photos are and how sleek and modern the site is!',
      photoCredit: 'Main + Simple Photography',
      photoUrl: 'https://www.mainandsimplephoto.com/',
      instaCredit: '@mainandsimple',
      instaUrl: 'https://www.instagram.com/mainandsimple/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-6.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-tina-marie-johnny/',
      names: 'Tina Marie &amp; Johnny',
      quote:
        'I have enjoyed the one-stop-shop idea. I can send the link to all of my guests and they can instantly know about the details, RSVP and even get to know the people in our wedding party.',
      photoCredit: 'Melissa Ivy Photography',
      photoUrl: 'http://www.melissaivy.com/',
      instaCredit: '@melissaivyphotography',
      instaUrl: 'https://www.instagram.com/melissaivyphotography/',
      showQuote: false,
    },
    {
      img: 'sitewide/block_testimonials/testimonial_rajesh-jayalekshmi-2.jpg',
      storyUrl: 'https://withjoy.com/blog/',
      names: 'Jayalekshmi & Rajesh',
      quote: 'You had really nice designs and color combinations. The web layout was quite neat.',
      photoCredit: 'Bluemonk Moments',
      photoUrl: 'https://www.facebook.com/permalink.php?id=168221357087318&story_fbid=465847230658061',
      instaCredit: '@bluemonkevents',
      instaUrl: 'https://www.instagram.com/bluemonkevents',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-21.jpeg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-malika-dyron/',
      names: 'Catalina & Charles',
      quote:
        'OMG, I cannot believe this service is free… once we decided on our wedding guests, it became a hub of keeping track of everyone and everything.',
      photoCredit: 'Lauren Murray',
      photoUrl: 'https://www.wildfolkphotography.com/',
      instaCredit: '@wildfolkphotography',
      instaUrl: 'https://www.instagram.com/wildfolkphotography/',
      showQuote: true,
    },
    {
      img: 'home/couples/testimonial-couples-18.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-malika-dyron/',
      names: 'Malika & Dyron',
      quote:
        'It was really easy to set up! Joy took the guesswork out of what should I include and how should I make it look. It also took hours of coding out of my hands – woot!',
      photoCredit: '',
      photoUrl: '/',
      instaCredit: '',
      instaUrl: '',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-5.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-tina-marie-johnny/',
      names: 'Tina Marie &amp; Johnny',
      quote:
        'I have enjoyed the one-stop-shop idea. I can send the link to all of my guests and they can instantly know about the details, RSVP and even get to know the people in our wedding party.',
      photoCredit: 'Melissa Ivy Photography',
      photoUrl: 'http://www.melissaivy.com/',
      instaCredit: '@melissaivyphotography',
      instaUrl: 'https://www.instagram.com/melissaivyphotography/',
      showQuote: true,
    },
    {
      img: 'home/couples/testimonial-couples-4.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-emily-collin/',
      names: 'Emily & Collin',
      quote: 'It’s so customizable! The layouts and the suggestions give the right push to make sure we cover all of the bases.',
      photoCredit: 'Photo by Hand + Arrow Photography',
      photoUrl: 'https://handandarrow.com/',
      instaCredit: '@handandarrowphoto',
      instaUrl: 'https://www.instagram.com/handandarrowphoto/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-8.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-daphne-jorge/',
      names: 'Daphne & Jorge',
      quote:
        'I fell in love with the interface. I’m also excited to have my guests download the app so they can post pictures and communicate.',
      photoCredit: 'Photo by RJ of Foxgrove Films',
      photoUrl: 'https://foxgrovefilms.com/',
      instaCredit: '@foxgrovefilms',
      instaUrl: 'https://www.instagram.com/foxgrovefilms/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-6.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-tina-marie-johnny/',
      names: 'Tina Marie &amp; Johnny',
      quote:
        'I have enjoyed the one-stop-shop idea. I can send the link to all of my guests and they can instantly know about the details, RSVP and even get to know the people in our wedding party.',
      photoCredit: 'Melissa Ivy Photography',
      photoUrl: 'http://www.melissaivy.com/',
      instaCredit: '@melissaivyphotography',
      instaUrl: 'https://www.instagram.com/melissaivyphotography/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-13.png',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-morgan-abe/',
      names: 'Morgan &amp; Abe',
      quote: 'I love the design! I’m a sucker for good visual and UX design. Joy looks good and is so intuitive for our guests!',
      photoCredit: 'Liv Lyszyk Photography',
      photoUrl: 'https://www.livlyszyk.com/',
      instaCredit: '@livlyszykphotography',
      instaUrl: 'https://www.instagram.com/livlyszykphotography/',
      showQuote: true,
    },
    {
      img: 'home/couples/testimonial-couples-10.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-kayla-randy/',
      names: 'Kayla & Randy',
      quote:
        'Living abroad and having everything come in and out of the same website is beyond perfect for us. This alone saves my sanity!',
      photoCredit: 'Menning Photographic',
      photoUrl: 'http://menningphotographic.com/',
      instaCredit: '@menningphotographic',
      instaUrl: 'https://www.instagram.com/menningphotographic/',
      showQuote: false,
    },
    {
      img: 'home/couples/testimonial-couples-15.jpg',
      storyUrl: 'https://withjoy.com/blog/joy-proposal-story-nicholas-micah/',
      names: 'Nicholas & Micah',
      quote: 'Joy thinks of everything when it comes to creating and designing a wedding website.',
      photoCredit: 'Shay & Olive Photography',
      photoUrl: 'https://www.shayandolive.com/',
      instaCredit: '@shayandolivephoto',
      instaUrl: 'https://www.instagram.com/shayandolivephoto',
      showQuote: false,
    },
  ],

  announcements: {
    image: 'home/joy-announcements/virtual-wed-announcement.png',
    description: {
      title: 'Virtual Weddings',
      heading: 'Plan your Virtual Wedding with Joy',
      content:
        'Stream virtual wedding events, send virtual wedding invitations, track RSVPs, collect guest photos, and more. Joy supports every step of your virtual wedding.',
      linkText: 'Learn More',
      lottieText: 'New',
    },
  },

  announcementsRegistry: {
    image: 'home/joy-announcements/reg-announcement-2.png',
    description: {
      title: 'The All-in-One Registry',
      heading: 'Build your happily ever after',
      content:
        'From gifts and charities to cash funds and experiences,  we’ve just launched the first step on our journey towards a new kind of wedding registry. This is your wedding registry, your way.',
      linkText: 'Learn More',
      lottieText: 'New',
    },
  },

  babyRegistry: {
    title: 'Joy Baby Registry.',
    body: 'Know someone on the parenting journey? Tell them about Joy! We’ve built smart, time-saving features no other registry has.',
    linkText: 'Explore Now',
    link: '/baby-registry',
  },

  roomBlock: {
    eyeBrow: 'Room Blocks',
    title: 'Save your guests up to 30% on hotel rooms.',
    body: 'Block discounted rooms for your guests close to your venue, with no credit card required from you.',
    linkText: 'Explore Now',
    link: '/hotel-room-blocks',
  },

  contactCollector: {
    eyeBrow: 'Contact Collector',
    title: 'Collect all your guests’ addresses with one magic link.',
    body:
      'Craft your magic link, share it with your guests and that’s it! They fill out a form that automatically updates their info on your guest list.',
    linkTextPrimary: 'Collect Addresses',
    linkPrimary: '/createwedding',
    linkTextSecondary: 'Learn More',
    linkSecondary: 'https://withjoy.com/blog/how-to-easily-collect-addresses-for-wedding-invitations/',
  },
} as const;
